import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import ja from 'vuetify/src/locale/ja'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { ja },
    current: 'ja',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#2196f3',
        secondary: '#e91e63',
        accent: '#009688',
        error: '#f44336',
        warning: '#8bc34a',
        info: '#607d8b',
        success: '#00bcd4',
      },
      dark: {
        primary: '#2196f3',
        secondary: '#e91e63',
        accent: '#009688',
        error: '#f44336',
        warning: '#8bc34a',
        info: '#607d8b',
        success: '#00bcd4',
      },
    },
  },
})
