import Vue from 'vue'
import '@/plugins/axios'
import Top from './Top.vue'
import '@/registerServiceWorker'
import router from '@/router/top'
import store from '@/store/top'
import vuetify from '@/plugins/vuetify/vuetify'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(Top),
}).$mount('#app')
