








import Vue from 'vue'
import Utils from '@/utils/utils'

export default Vue.extend({
  name: 'Top',

  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    $route(to, from) {
      Utils.setPageTitle(to)
    },
  },
  data: () => ({
    //
  }),
})
